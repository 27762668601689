.navbar-options {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.navbar-options div {
	width: 33%;
}

.navbar-options div:first {
	text-align: left;
	padding-left: 16px;
}